@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

html, body {
  padding: 0;
  margin: 0;
}

html {
  background: url('../img/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Rubik';
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  text-align: center;
  padding: 12px 0 0 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.content {
  background-color: rgba(0,0,0,0.8);
  padding: 2em;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: left;

  h2 {
    //background: rgba(255,255,255,0.1);
    padding: 0.1em;
    margin: 0;
  }

  input, textarea {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: dashed;
    font-size: 16px;
    width: 100%;
    border-color: rgba(255,255,255,0.5);
    line-height: 32px;
    background-color: transparent;
    color: white;
    font-family: "Rubik";
    padding: 1em;
    box-sizing: border-box;
    &focus {
      outline: none;
      border-color: rgba(255,255,255,0.9);
    }
  }

  textarea {
  }

  .dropzone {
    flex: 1;
    margin-top: 16px;
    padding: 20px;
    border: 2px dashed rgba(255,255,255,0.5);
  }

  .image-uploader {
    display: flex;
  }

  .uploadFile {
    border: 4px dashed rgba(4, 4, 5, 0.1);
    border-radius: 16px;
    text-align: center;
    padding: 32px 60px;
  }

  form.horizontal {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1vw;
    grid-auto-rows: minmax(40px, auto);

    .span-all {
      grid-column: 1/3;
    }
  }

  .content {

    &.success, &.error, &.pending {
      text-align: center;

      .shoap-token-id {
        font-size: 8em;
        font-weight: bold;
        padding: 1em;
      }

      .note {
        padding: 1em 1em 2em 1em;
      }
    }
  }

  .btn.primary {
    margin-top: 1.5em;
  }
}

.btn {
  padding: 9px 16px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  cursor:pointer;

  &.primary {
    background-color: #00d900;
    width: 100%;
  }

  &:disabled, &:disabled:hover {
    background-color: #909b8e;
    color: #676e66;
    border-color: #676e66
  }
  &:hover {
    background-color: #90fa90;
    color: #ffffff;
    border-color: #ffffff
  }

}

#status {
  color:red;
}
